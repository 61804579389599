import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-352b64ea"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "steps"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "color": "red"
  }
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 0,
  style: {
    "color": "red"
  }
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "button"
};
const _hoisted_7 = {
  key: 0,
  class: "AuditReason"
};
import { ref } from 'vue';
import { CircleCheck, CircleClose } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
export default {
  __name: 'modal-examine',

  setup(__props, {
    expose: __expose
  }) {
    const dialogVisible = ref(false); // const formRef = ref()

    const InfoData = ref();
    const activevalue = ref(); // 步骤条的进度
    // 是否通榆审核

    const ISAuditReason = ref();

    const showModal = data => {
      console.log(data);
      InfoData.value = data;
      activevalue.value = data.progress;
      ISAuditReason.value = true;

      if (data.progress === 4) {
        // 失败
        activevalue.value = 2;
      }

      if (data.progress === 5) {
        // 失败
        activevalue.value = 3;
      }

      dialogVisible.value = true;
    }; // 取消
    // const cancelModal = () => {
    //   dialogVisible.value = false
    // }
    // // 保存按钮
    // const submitForm = async (formEl) => {
    //   dialogVisible.value = false
    // }


    const checkList = ref(['Value selected and disabled', 'Value A']); // 确定

    const examineOK = () => {
      dialogVisible.value = false;
    }; // 拒绝


    const examineNO = () => {
      ISAuditReason.value = false;
    }; // 返回


    const examineBack = () => {
      ISAuditReason.value = true;
    }; // 拒绝时的确定


    const NOexamineOK = () => {
      ElMessage({
        message: '提交成功',
        type: 'success'
      });
      dialogVisible.value = false;
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_step = _resolveComponent("el-step");

      const _component_el_steps = _resolveComponent("el-steps");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_checkbox = _resolveComponent("el-checkbox");

      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => dialogVisible.value = $event),
        title: "审核",
        width: "50%",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_steps, {
          style: {
            "width": "800px"
          },
          active: activevalue.value,
          "align-center": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_step, {
            title: "申请提交"
          }, {
            icon: _withCtx(() => [activevalue.value >= 1 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: "26"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleCheck))]),
              _: 1
            })) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_step, null, _createSlots({
            title: _withCtx(() => [InfoData.value.progress == 4 ? (_openBlock(), _createElementBlock("text", _hoisted_2, "服务站审核")) : (_openBlock(), _createElementBlock("text", _hoisted_3, "服务站审核"))]),
            _: 2
          }, [activevalue.value >= 2 ? {
            name: "icon",
            fn: _withCtx(() => [InfoData.value.progress == 4 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: "26",
              color: "red"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleClose))]),
              _: 1
            })) : activevalue.value >= 2 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              size: "26"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleCheck))]),
              _: 1
            })) : _createCommentVNode("", true)]),
            key: "0"
          } : undefined]), 1024), _createVNode(_component_el_step, null, _createSlots({
            title: _withCtx(() => [InfoData.value.progress == 5 ? (_openBlock(), _createElementBlock("text", _hoisted_4, "服务站开票")) : (_openBlock(), _createElementBlock("text", _hoisted_5, "服务站开票"))]),
            _: 2
          }, [activevalue.value >= 3 ? {
            name: "icon",
            fn: _withCtx(() => [InfoData.value.progress == 5 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: "26",
              color: "red"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleClose))]),
              _: 1
            })) : activevalue.value >= 3 ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              size: "26"
            }, {
              default: _withCtx(() => [_createVNode(_unref(CircleCheck))]),
              _: 1
            })) : _createCommentVNode("", true)]),
            key: "0"
          } : undefined]), 1024)]),
          _: 1
        }, 8, ["active"])]), _createElementVNode("div", _hoisted_6, [ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          type: "danger",
          onClick: examineNO
        }, {
          default: _withCtx(() => [_createTextVNode(" 拒绝 ")]),
          _: 1
        })) : _createCommentVNode("", true), ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          type: "success",
          onClick: examineOK
        }, {
          default: _withCtx(() => [_createTextVNode(" 确认 ")]),
          _: 1
        })) : _createCommentVNode("", true), !ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_button, {
          key: 2,
          type: "success",
          onClick: examineBack
        }, {
          default: _withCtx(() => [_createTextVNode(" 返回 ")]),
          _: 1
        })) : _createCommentVNode("", true), !ISAuditReason.value ? (_openBlock(), _createBlock(_component_el_button, {
          key: 3,
          type: "success",
          onClick: NOexamineOK
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })) : _createCommentVNode("", true)]), !ISAuditReason.value ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_el_checkbox_group, {
          modelValue: checkList.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => checkList.value = $event),
          class: "AuditReasoncheckbox"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_checkbox, {
            label: "原因1",
            value: "Value A"
          }), _createVNode(_component_el_checkbox, {
            label: "原因2",
            value: "Value B"
          }), _createVNode(_component_el_checkbox, {
            label: "原因3",
            value: "Value C"
          })]),
          _: 1
        }, 8, ["modelValue"])])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};