import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { reactive, ref, computed } from 'vue';
const formLabelWidth = '100px';
export default {
  __name: 'modal-editInvoice',

  setup(__props, {
    expose: __expose
  }) {
    const dialogVisible = ref(false);
    const formRef = ref();
    const formModel = reactive({
      afterSaleId: 9,
      trade_uuid: null,
      userId: null,
      reason: null,
      text: null,
      photoUrls: null,
      returnTrackingPhone: null,
      returnTrackingNumber: null,
      node: null,
      state: null,
      stateInfo: null,
      orderStatus: null
    });

    const showModal = data => {
      Object.assign(formModel, data);
      dialogVisible.value = true;
    };

    const hideModal = () => {
      dialogVisible.value = false;
    };

    const title = computed(() => {
      return `发票ID: ${formModel.id}`;
    });

    const editOrderFn = async () => {
      //   const resData = await editAfterSales(formModel)
      //   if (resData && resData.status === 'success') {
      //     ElMessage({
      //       message: '编辑成功！',
      //       type: 'success'
      //     })
      //     emit('refresh')
      //     hideModal()
      //   }
      hideModal();
    };

    const submitForm = async formEl => {
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
          editOrderFn();
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_card = _resolveComponent("el-card");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => dialogVisible.value = $event),
        title: "编辑发票信息",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          onClick: _cache[7] || (_cache[7] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_card, {
          class: "box-card mb-20"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(title.value), 1)])])]),
          _: 1
        }), _createVNode(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "抬头类型",
                prop: "buyer_type",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.buyer_type,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.buyer_type = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "抬头名称",
                prop: "buyer_name",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "input",
                  modelValue: formModel.buyer_name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.buyer_name = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "单位名称",
                prop: "company_name",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.company_name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.company_name = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "单位税号",
                prop: "company_tax_no",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.company_tax_no,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.company_tax_no = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "单位税号",
                prop: "company_address",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.company_address,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.company_address = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "单位联系电话",
                prop: "telphone",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.telphone,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.telphone = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "开户银行",
                prop: "bank_name",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.bank_name,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.bank_name = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};